<template>
    <el-collapse v-model="collapse">
        <el-collapse-item name="1">
            <template #title>
                <Heading>Händelser ({{ events.length }})</Heading>
            </template>
            <el-timeline>
                <el-timeline-item v-for="(event, index) in events" :key="index" :type="getType(event.type)" :timestamp="formatDate(event.eventTime)">
                    <el-card>
                        <h4 class="font-bold">{{ event.type }} - {{ event.doneByUser }}</h4>
                        <p>{{ event.comment }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-collapse-item>
    </el-collapse>
</template>

<script>
import moment from "moment";

export default {
    props: ["events"],
    data() {
        return {
            collapse: "0",
        };
    },
    components: {
        Heading: () => import(/* webpackChunkName: "DateInputField" */ "@/components/content/Heading.vue"),
    },
    methods: {
        formatDate(date) {
            return date
                ? moment
                      .utc(date)
                      .local()
                      .format("YYYY-MM-DD")
                : "";
        },
        getType(eventType) {
            switch (eventType) {
                case "Godkänd":
                    return "success";
                case "Nekad":
                    return "danger";
                case "Utkast":
                    return "info";
                case "Skapad":
                    return "info";
                case "Uppdaterad":
                    return "primary";
                case "Kompletteras":
                    return "warning";
                case "Komplettering":
                    return "success";
                case "Makulerad":
                    return "danger";
            }
        },
    },
};
</script>

<style class="postcss">
.el-collapse-item__wrap,
.el-collapse-item__header {
    background-color: transparent !important;
}
</style>
