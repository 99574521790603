<template>
    <el-table :data="participants" style="width: 100%" height="250">
        <el-table-column prop="name" :label="'Deltagare (' + participants.length + ')'" />
        <el-table-column align="right">
            <template #default="scope">
                <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">Radera</el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    props: {
        participants: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        handleDelete(index) {
            this.$emit("delete", index);
        },
    },
};
</script>
