import { render, staticRenderFns } from "./ReceiptDialogs.vue?vue&type=template&id=5555f7b6&scoped=true&"
import script from "./ReceiptDialogs.vue?vue&type=script&lang=js&"
export * from "./ReceiptDialogs.vue?vue&type=script&lang=js&"
import style0 from "./ReceiptDialogs.vue?vue&type=style&index=0&id=5555f7b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5555f7b6",
  null
  
)

export default component.exports